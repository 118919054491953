import avatar from '../assets/saintrien.jpg'
import github from '../assets/github_icon.png'
import facebook from '../assets/facebook_icon.png'
import twitter from '../assets/twitter_icon.png'
import linkedIn from '../assets/linkedIn_icon.png'
import medium from '../assets/medium_icon.png'
import Header from './Header'

import {
  useGlobalState,
} from '../store'
import MintButton from './MintButton'
import { ethers } from 'ethers'

import React, {useEffect} from 'react';


import {
    useContractWrite,
    useContractRead,
    useWaitForTransaction,
    usePrepareContractWrite,
    useAccount
} from 'wagmi';


const Hero = (props) => {
  const [contractAddress] = useGlobalState('contractAddress')
  const { address } = useAccount()

  console.log('rendering Hero', contractAddress)

  if(!contractAddress){
    return <h1>Loading</h1>
  }
  else{

    return <div className="main">
      <Header />
      

      <div className="inner">
        
        <div className="title">
        <h1>Le Femme</h1>
        <p>
        111 editions. 3 unique cover art versions. Minting 11.18.22.
        </p>
        </div>

        <div className="mainSec mint">
        <div className="innerSec">
        <div className="artCont">
        <div className="smallArt">        
        <div className="imgWrap">
        <img src='https://ipfs.io/ipfs/bafybeifg5kf5ubqkl77wkzkpfqstj376ipcwwl6tbcog236fcd3q2s2ice/1.png'/>
        </div>
        </div>
        <div className="smallArt">
        <div className="imgWrap">        
        <img src='https://ipfs.io/ipfs/bafybeifg5kf5ubqkl77wkzkpfqstj376ipcwwl6tbcog236fcd3q2s2ice/2.png'/>
        </div>
        <div className="imgWrap">        
        <img src='https://ipfs.io/ipfs/bafybeifg5kf5ubqkl77wkzkpfqstj376ipcwwl6tbcog236fcd3q2s2ice/3.png'/>
        </div>
        </div>
        </div>
        <div className="audioWrapper">
        <audio controls controlsList="nodownload noplaybackrate">
          <source src="https://ipfs.io/ipfs/bafybeifkgm3n6fvrjhna2iiyo4tv6fms5mnuopibn5k264fw3ttwd4ydga/lefemme.wav" />
          Your browser does not support the audio element.
        </audio>
        </div>
        </div>
        </div>

        <div className="mainSec product">
        <div className="innerSec">        
        <div>
        <h3>Solo</h3>
        <p>One NFT with randomly selected cover art version</p>
        <p id="price">0.02 ETH + Gas</p>
        </div>
        <MintButton type="solo"/>
        </div>
        </div>

        <div className="mainSec product">
        <div className="innerSec">        
        <div>
        <h3>Full Set</h3>
        <p>Three NFTs with each available cover art version</p>
        <p id="price">0.05 ETH + Gas</p>
        </div>
        <MintButton type="bundle"/>
        </div>
        </div>

        <div className="mainSec product">
        <div className="innerSec">        
        <div>
        <h3>Full Set <span id="promo2">Petale Special</span></h3>
        <p>Three NFTs with each available cover art version (1 Petale = 1 Set)</p>
        <p id="price">0 ETH + Gas</p>
        </div>
{        <MintButton type="whitelist"/>
}        </div>
        </div>


{/*        <div className="mainSec nftSec">
          <div className="preview">
            <img src="roseb2.gif" alt="loading..." id="roseVideo"/>
          </div>
          <div className="buttonCont">
          {!props.address || !address ? <p>No wallet connected</p> : 
          <MintButton address={props.address.toLowerCase()}/>
          }
          </div>
        </div>
*/}      </div>
      <p id="footer">a <a href="https://twitter.com/volrecords_">Vol Records</a> project</p>
    </div>    
  }


}

export default Hero


/* minting process:
if not whitelisted:
- mint button changes
- countdown for normal mint
else:
- get proof

when mint pressed:
- error handeling for metamask
- error handeling for anything else
*/