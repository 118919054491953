import { createGlobalState } from 'react-hooks-global-state'

const { setGlobalState, useGlobalState, getGlobalState } = createGlobalState({
  alert: { show: false, msg: '', color: '' },
  loading: { show: false, msg: '' },
  contract: null,
  maxSupply: 10,
  amountMinted: "?",
  connectedAccount: '',
  nfts: [],
  whitelistAddresses: [[
    "0x32ec773ed7b5a54d1f0ac77944caf76ed30e6b86","0x7933231775dec3f3c80039debd7e3afd8a81f674","0x96acf191c0112806f9709366bad77642b99b21a9","0x9c21564d7b03dba5f494c93733ecad1fa0129f7c","0x9c6f3006085575d711a2b37f639047e9232c1759","0xa92e8d7667faf4527476d12e042399607b974637","0xbeeb460081c1e3820a6afaa309c6d3c45f465335","0xc58d4fa33ee60dc606544f23720150b2080bf3ce","0xc785fd4e9aee80a6d6cbfcc57380b92aa92deef3","0xd46b94375251d1a6caebae37953eaf2177e6de52","0xe055721b972d58f0bcf6370c357879fb3a37d2f3","0xe1da9e3ea9efc074ebffd4d2bed209b370705188","0xe53b5c369401e4ba22961f58cbb40538d325aaa3","0xed22bb0106c24c7f6b4d8aae33639e1467061f64"
  ], [
      "0x041526c8dce0d36e04d4668c1f3d425cb9548729","0x703f87c50d915775228e40a0f562552e291e5540","0x7b753919b953b1021a33f55671716dc13c1eae08"
  ], [
      "0xde0cbd5df89bb67ab804db21e9b931bad4200392"
  ], [
      "0x58452efeabc31eef4f74ae0002fac6cb03c1229e"
  ]],
    contractAddress: "0x18C56C654f481D75fF451018E71eaE8ef34f22ce"
  })

const setAlert = (msg, color = 'green') => {
  setGlobalState('alert', { show: true, msg, color })
  setTimeout(() => {
    setGlobalState('alert', { show: false, msg: '', color })
    setGlobalState('loading', false)
  }, 8000)
}

const setLoadingMsg = (msg) => {
  const loading = getGlobalState('loading')
  setGlobalState('loading', { ...loading, msg })
}

export {
  useGlobalState,
  setGlobalState,
  getGlobalState,
  setAlert,
  setLoadingMsg,
}
