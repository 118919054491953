
import Main from './components/Main'
import Header from './components/Header'


import { useEffect } from 'react'
import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme
} from '@rainbow-me/rainbowkit';
import {
  chain,
  configureChains,
  createClient,
  WagmiConfig,
  useAccount
} from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { Buffer } from "buffer/";

window.Buffer = window.Buffer || Buffer;

if(!window){
  console.log('no window')
}

const { chains, provider } = configureChains(
  [chain.mainnet],
  [
    alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY }),
  ]
);

const { connectors } = getDefaultWallets({
  appName: 'Test',
  chains
});

const wagmiClient = createClient({
  autoConnect: false,
  connectors,
  provider
})

const App = () => {



  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} theme={darkTheme({
      fontStack: 'system',
    })} modalSize="compact">
        <div/>
        <Main />
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default App
