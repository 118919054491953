import React from 'react'
import Axios from 'axios';

import { useEffect, useState } from 'react';
import { ethers } from 'ethers';

import { ConnectButton } from '@rainbow-me/rainbowkit';
import Hero from './Hero.jsx'

import {
    useContractWrite,
    useWaitForTransaction,
    usePrepareContractWrite,
    useContractRead,
    useAccount
} from 'wagmi';


const Main = ()=> {
    const account = useAccount()
    var address = account['address']

    return (<div className="outer">
        <img src="sr_bg.gif" id="myVideo"/>
        <Hero address={address}/>
        </div>)
  }

export default Main
