
import {
    useGlobalState,
    setGlobalState,
} from '../store'
import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";
import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import {
    useContractWrite,
    useWaitForTransaction,
    usePrepareContractWrite,
    useContractRead,
    useAccount
} from 'wagmi';
import contractABI from '../assets/contract-abi.json';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { anvAbi } from '../assets/anv-abi.ts'

//import { MerkleTree } from "merkletreejs";
//import keccak256 from "keccak256";

const Wrapper = (props)=>{
    var { address } = useAccount()
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [whitelistAddresses] = useGlobalState('whitelistAddresses')
    const [contractAddress] = useGlobalState('contractAddress')
    const [started, setStarted] = useState()

    useEffect(() => {
        setIsPageLoaded(true)
    }, []);

    const { data, isError, isLoading } = useContractRead({
        addressOrName: contractAddress,
        contractInterface: contractABI,
        functionName: 'mintStarted',        
        onError(error) {
            console.log('error mintStarted', error)
        },
        onSuccess(data) {
          console.log('Success mintStarted', data)
          setStarted(data)
        }
    })


    let type = props.type;

    if(started !== true){
        return <button>
            Minting not started
        </button>
    }

    if(address){
        address = address.toLowerCase();   
    }
    else{
        return <div className="connectCont2">
        <ConnectButton />
        </div>
    }

    if(type=="solo"){
        return <MintSolo /> 
    }
    else if(type=="bundle"){
        return <MintBundle />
    }
    else if(type =="whitelist"){
        let white_list = []
        for (let i = 0; i < whitelistAddresses.length; i++) {
        if (whitelistAddresses[i].includes(address)) {
            white_list = whitelistAddresses[i]
        }
        }
        let leafNodes = white_list.map(addr => keccak256(addr));
        if(leafNodes.length>0){
            let merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
            let rootHash = merkleTree.getRoot();
            let rootHashBytes32 = "0x" + merkleTree.getRoot().toString("hex");
            var claimingAddress = leafNodes[white_list.indexOf(address)];
            const hexProof = merkleTree.getHexProof(claimingAddress);
            var keyInfo = {
                claimingAddress: claimingAddress,
                hexProof: hexProof,
                rootHashBytes32: rootHashBytes32
            }
            return <MintWL myData={keyInfo}/>
        } 
        else{
            return <h5 id="notFound">Address not found on the Saint List</h5>
        }
    }

    return <></>

}


const MintSolo = (myData) => {
    const { address } = useAccount()
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [ready, setReady] = useState(false);
    const [contractAddress] = useGlobalState('contractAddress')
    const [reason, setReason] = useState();
    const [minted, setMinted] = useState(false);
    const [minting, setMinting] = useState(false);
    const [open, setOpen] = useState()
    useEffect(() => {
        setIsPageLoaded(true)
    }, []);

    const { config, error } = usePrepareContractWrite({
        addressOrName: contractAddress,
        contractInterface: contractABI,
        functionName: "safeMint",
        overrides: {
          from: address,
          value: ethers.utils.parseEther('0.02'),
        },        
            onError(error) {
            console.log('set error usePrepareContractWrite', error, typeof error)
            setReason(error.reason)
            setReady(false)
        },
        onSuccess(data) {
          console.log('Success usePrepareContractWrite', data)
          setReady(true)
        }
    })


    const { data, isLoading, isSuccess, write: safeMint } = useContractWrite(config,
            {
                onMutate(args, overrides) {
                    console.log("mutate")
                },
                onError() {
                    console.log("error useContractWrite", error)
                },
                onSettled() {
                    console.log("done")
                }
            })

    const { isLoading2, isSuccess2 } = useWaitForTransaction({
        hash: data?.hash,
    })

    const waitForTransaction = useWaitForTransaction({
        hash: data?.hash,
        onSuccess(data) {
          console.log('Wait Success', data)
          setMinting(true)
        },
        onSettled() {
            setGlobalState('loading', { show: false, msg: '' })
            console.log('finished minting')
            setMinted(true)
        },
        onError() {
            console.log("error")
        }
    })

    return <MintButton mode={safeMint} minted={minted} isSuccess={isSuccess} isLoading={isLoading}/>
}

const MintBundle = (myData) => {
    const { address } = useAccount()
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [ready, setReady] = useState(false);
    const [contractAddress] = useGlobalState('contractAddress')
    const [reason, setReason] = useState();
    const [minted, setMinted] = useState(false);
    const [minting, setMinting] = useState(false);
    const [open, setOpen] = useState()
    useEffect(() => {
        setIsPageLoaded(true)
    }, []);

    const { config, error } = usePrepareContractWrite({
        addressOrName: contractAddress,
        contractInterface: contractABI,
        functionName: "safeMintBundle",
        overrides: {
          from: address,
          value: ethers.utils.parseEther('0.05'),
        },        
            onError(error) {
            console.log('set error usePrepareContractWrite', error, typeof error)
            setReason(error.reason)
            setReady(false)
        },
        onSuccess(data) {
          console.log('Success usePrepareContractWrite', data)
          setReady(true)
        }
    })

    const { data, isLoading, isSuccess, write: safeMintBundle } = useContractWrite(config,
            {
                onMutate(args, overrides) {
                    console.log("mutate")
                },
                onError() {
                    console.log("error useContractWrite", error)
                },
                onSettled() {
                    console.log("done")
                }
            })

    const { isLoading2, isSuccess2 } = useWaitForTransaction({
        hash: data?.hash,
    })

    const waitForTransaction = useWaitForTransaction({
        hash: data?.hash,
        onSuccess(data) {
          console.log('Wait Success', data)
          setMinting(true)
        },
        onSettled() {
            setGlobalState('loading', { show: false, msg: '' })
            console.log('finished minting')
            setMinted(true)
        },
        onError() {
            console.log("error")
        }
    })        
 

    return <MintButton mode={safeMintBundle} minted={minted} isSuccess={isSuccess} isLoading={isLoading}/>
}

const MintWL = (myData) => {
    const { address } = useAccount()
    const [contractAddress] = useGlobalState('contractAddress')
    const [reason, setReason] = useState();
    const [minted, setMinted] = useState(false);
    const [minting, setMinting] = useState(false);
    const [open, setOpen] = useState()

    let claimingAddress = myData['myData']['claimingAddress']
    let hexProof = myData['myData']['hexProof']
    let rootHashBytes32 = myData['myData']['rootHashBytes32']
    var string = new TextDecoder().decode(claimingAddress);

    const { config, error } = usePrepareContractWrite({
        addressOrName: contractAddress,
        contractInterface: contractABI,
        functionName: "whitelistMint",
        args: [hexProof, rootHashBytes32],        
        overrides: {
          from: address,
          value: ethers.utils.parseEther('0'),
        },        
            onError(error) {
            console.log('set error usePrepareContractWrite', error, typeof error)
            setReason(error.reason)
        },
        onSuccess(data) {
          console.log('Success usePrepareContractWrite', data)
        }
    })

    const { data, isLoading, isSuccess, write: whitelistMint } = useContractWrite(config,
            {
                onMutate(args, overrides) {
                    console.log("mutate")
                },
                onError() {
                    console.log("error useContractWrite", error)
                },
                onSettled() {
                    console.log("done")
                }
            })

    const { isLoading2, isSuccess2 } = useWaitForTransaction({
        hash: data?.hash,
    })

    const waitForTransaction = useWaitForTransaction({
        hash: data?.hash,
        onSuccess(data) {
          console.log('Wait Success', data)
          setMinting(true)
        },
        onSettled() {
            setGlobalState('loading', { show: false, msg: '' })
            console.log('finished minting')
            setMinted(true)
        },
        onError() {
            console.log("error")
        }
    })        

    return <MintButton mode={whitelistMint} minted={minted} isSuccess={isSuccess} isLoading={isLoading}/>
}

var MintButton = ({mode, minted, isSuccess, isLoading}) => {

    var button = <button
            className="shadow-xl shadow-black text-white
            bg-[#e32970] hover:bg-[#bd255f] p-2
            rounded-full cursor-pointer my-4"
            onClick={mode}
        >
            Mint Now
        </button >

    if(minted) {
        button = <button>
        Mint Complete
        </button>    
    }
    else if(isSuccess){
        button = <button>
        Minting
        </button>
    }
    else if(isLoading){
        button = <button>
        Confirm Transaction
        </button>
    }
    
    return (
       button
    )

}


export default Wrapper